interface IProps {
  pathColor?: string;
  className?: string;
}

export const IconChat = ({ pathColor, className }: IProps) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
    >
      <title>chat</title>
      <path
        fill={pathColor || '#000000'}
        d="M2 4v17.8l3.56-3.56h3.56v-10.68h10.68v-3.56h-17.8zM12.68 11.12v14.24h14.24l3.56 3.56v-17.8h-17.8z"
      />
    </svg>
  );
};

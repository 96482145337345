interface IProps {
  pathColor?: string;
  className?: string;
}

export const IconSearch = ({ pathColor, className }: IProps) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
    >
      <title>search</title>
      <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2"
        stroke={pathColor || '#000000'}
        d="M22.236 22.236l6.436 6.436"
      />
      <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2"
        stroke={pathColor || '#000000'}
        d="M14.191 24.381c5.628 0 10.191-4.562 10.191-10.191s-4.562-10.191-10.191-10.191c-5.628 0-10.191 4.562-10.191 10.191s4.562 10.191 10.191 10.191z"
      />
    </svg>
  );
};

'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { pageColor } from '@/constants/color';
import { pagePath } from '@/constants/pagePath';
import { useNotifications } from '@/providers/NotificationProvider';
import { NotificationType } from '@/types/notifications';

import { Icon } from '../Icon/Icon';

export const BottomNavigation = () => {
  const currentPath = usePathname();
  const { notifications } = useNotifications();
  const topPageLink = pagePath.account.top;

  const hasMyPageNotifications = notifications.some(
    (notification) =>
      notification.type === NotificationType.AgeVerificationComplete ||
      notification.type === NotificationType.AgeVerificationRejected ||
      notification.type === NotificationType.ProfileImageApproved ||
      notification.type === NotificationType.ProfileImageRejected ||
      notification.type ===
        NotificationType.UserProfileGenericTextboxDataApproved ||
      notification.type ===
        NotificationType.UserProfileGenericTextboxDataRejected ||
      notification.type === NotificationType.LikesBonusGranted ||
      notification.type === NotificationType.Match,
  );

  const hasLikeNotifications = notifications.some(
    (notification) => notification.type === NotificationType.Like,
  );

  const hasChatNotifications = notifications.some(
    (notification) => notification.type === NotificationType.NewMessage,
  );

  // currentPathはクエリパラメータを含まないので、pagePathからパラメータを省いた文字列と比較
  const comparePathWithoutQuery = (path: string) => {
    const pagePathWithoutQuery = path.split('?')[0];
    return currentPath === pagePathWithoutQuery;
  };
  return (
    <div className="fixed inset-x-0 bottom-0 z-50 w-full bg-white p-4">
      <ul className="mx-auto flex max-w-screen-md justify-between">
        <li>
          <Link
            href={topPageLink}
            className="relative flex flex-col items-center"
          >
            <Icon
              iconName="search"
              className="size-6"
              pathColor={
                currentPath === pagePath.account.top
                  ? pageColor.subcolor02
                  : '#000000'
              }
            />
            <span
              className={`${
                currentPath === pagePath.account.top
                  ? 'text-page-subcolor02'
                  : 'text-black'
              } u-text-xxxs mt-1`}
            >
              さがす
            </span>
          </Link>
        </li>
        <li>
          <Link
            href={pagePath.account.favored}
            className="relative flex flex-col items-center"
          >
            {hasLikeNotifications && (
              <div className="absolute -right-2 top-0 size-2 rounded bg-page-subcolor02" />
            )}
            <Icon
              iconName="heartFill"
              className="size-6"
              pathColor={
                comparePathWithoutQuery(pagePath.account.favored)
                  ? pageColor.subcolor02
                  : '#000000'
              }
            />
            <span
              className={`${
                comparePathWithoutQuery(pagePath.account.favored)
                  ? 'text-page-subcolor02'
                  : 'text-black'
              } u-text-xxxs mt-1`}
            >
              いいね
            </span>
          </Link>
        </li>
        <li>
          <Link
            href={pagePath.chat}
            className="relative flex flex-col items-center"
          >
            {hasChatNotifications && (
              <div className="absolute -right-2 top-0 size-2 rounded bg-page-subcolor02" />
            )}
            <Icon
              iconName="chat"
              className="size-6"
              pathColor={
                currentPath === pagePath.chat ? pageColor.subcolor02 : '#000000'
              }
            />
            <span
              className={`${
                currentPath === pagePath.chat
                  ? 'text-page-subcolor02'
                  : 'text-black'
              } u-text-xxxs mt-1`}
            >
              チャット
            </span>
          </Link>
        </li>
        <li>
          <Link
            href={pagePath.mypage}
            className="relative flex flex-col items-center"
          >
            {hasMyPageNotifications && (
              <div className="absolute -right-2 top-0 size-2 rounded bg-page-subcolor02" />
            )}
            <Icon
              iconName="user"
              className="size-6"
              pathColor={
                currentPath === pagePath.mypage
                  ? pageColor.subcolor02
                  : '#000000'
              }
            />
            <span
              className={`${
                currentPath === pagePath.mypage
                  ? 'text-page-subcolor02'
                  : 'text-black'
              } u-text-xxxs mt-1`}
            >
              マイページ
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

import React from 'react';

import module from './Icon.module.css';
import { IconArrow } from './IconData/Icon-arrow';
import { IconBlank } from './IconData/Icon-blank';
import { IconChat } from './IconData/Icon-chat';
import { IconClose } from './IconData/icon-close';
import { IconHeartFill } from './IconData/Icon-heartFill';
import { IconHeartStroke } from './IconData/Icon-heartStroke';
import { IconSearch } from './IconData/Icon-search';
import { IconUser } from './IconData/Icon-user';

interface IProps {
  className?: string;
  iconName:
    | 'chat'
    | 'heartFill'
    | 'heartStroke'
    | 'search'
    | 'user'
    | 'arrow'
    | 'close'
    | 'blank';
  rotateArrow?: 'right' | 'top' | 'bottom' | undefined;
  pathColor?: string;
}
const Components = {
  chat: IconChat,
  close: IconClose,
  arrow: IconArrow,
  heartStroke: IconHeartStroke,
  heartFill: IconHeartFill,
  search: IconSearch,
  user: IconUser,
  blank: IconBlank,
};

const Wrapper = ({
  className,
  children,
  addClass,
}: {
  children: React.ReactNode;
  addClass?: string;
  className?: string;
}) => (
  <div className={`${module.iconWrapper} ${className} ${addClass}`}>
    {children}
  </div>
);

export const Icon = ({
  className,
  iconName,
  pathColor,
  rotateArrow,
}: IProps) => {
  const IconComponent = Components[`${iconName}`];
  let rotateClass = '';
  switch (rotateArrow) {
    case 'right':
      rotateClass = 'rotate-180';
      break;
    case 'top':
      rotateClass = 'rotate-90';
      break;
    case 'bottom':
      rotateClass = '-rotate-90';
      break;
    default:
      break;
  }
  return (
    <Wrapper className={className} addClass={rotateClass}>
      <IconComponent className={module.svg} pathColor={pathColor} />
    </Wrapper>
  );
};

interface IProps {
  pathColor?: string;
  className?: string;
}

export const IconUser = ({ pathColor, className }: IProps) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
    >
      <title>user</title>
      <path
        fill={pathColor || '#000000'}
        d="M5.333 29.333c0-2.829 1.124-5.542 3.124-7.543s4.713-3.124 7.542-3.124c2.829 0 5.542 1.124 7.543 3.124s3.124 4.714 3.124 7.543h-21.333zM16 17.333c-4.42 0-8-3.58-8-8s3.58-8 8-8c4.42 0 8 3.58 8 8s-3.58 8-8 8z"
      />
    </svg>
  );
};

export const pageColor = {
  // text
  black: '#333',
  mainText: '#333',
  white: '#fff',
  // text
  subText: '#757575',
  // bg
  bg: '#FFF5F5',
  bgMid: '#FFEBE9',
  bgEnd: '#FFE2EE',
  // formBorder
  formBorder: '#DEDEDE',
  // red
  danger: '#DF0101',
  dangerLabel: '#BF0000',
  // green
  success: '#047205',
  // purple
  subcolor01: '#6C41DC',
  subcolor12: '#F0EAFF',
  // pink
  subcolor02: '#F74481',
  subcolor10: '#FFCEE0',
  subcolor13: '#FFEBF2',
  subcolor15: '#FFE7EF',
  subcolor16: '#FF6499',
  // blue
  subcolor03: '#448CF7',
  subcolor08: '#35A8C1',
  // gray
  subcolor04: '#C6C6C6',
  subcolor05: '#6A6A6A',
  subcolor06: '#939393',
  subcolor11: '#E1E1E1',
  subcolor14: '#EAEAEA',
  // yellow
  subcolor07: '#FFE500',
  subcolor09: '#FFF9C0',
  subcolor17: '#FFBB19',
};

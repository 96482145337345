interface IProps {
  pathColor?: string;
  className?: string;
}

export const IconClose = ({ pathColor, className }: IProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.218 0c-0.441 0-0.873 0.132-1.239 0.379s-0.65 0.597-0.816 1.006c-0.166 0.409-0.206 0.858-0.116 1.29s0.308 0.827 0.625 1.135l12.185 12.185-12.196 12.192c-0.206 0.206-0.37 0.451-0.482 0.721s-0.169 0.558-0.169 0.85c-0 0.292 0.057 0.58 0.169 0.85s0.275 0.514 0.481 0.721 0.451 0.37 0.721 0.481c0.27 0.112 0.558 0.169 0.85 0.169s0.58-0.057 0.85-0.169c0.269-0.112 0.514-0.275 0.721-0.482l12.192-12.196 12.185 12.185c0.205 0.213 0.449 0.383 0.72 0.5s0.563 0.179 0.858 0.182c0.295 0.003 0.588-0.053 0.861-0.164s0.522-0.277 0.73-0.485c0.209-0.209 0.374-0.457 0.485-0.73s0.167-0.566 0.164-0.861c-0.003-0.295-0.065-0.587-0.182-0.858s-0.287-0.516-0.5-0.72l-12.185-12.185 12.172-12.171c0.213-0.204 0.383-0.449 0.5-0.72s0.179-0.563 0.182-0.858-0.053-0.588-0.164-0.861c-0.112-0.273-0.277-0.522-0.485-0.73s-0.457-0.374-0.73-0.485c-0.273-0.112-0.566-0.168-0.861-0.165s-0.587 0.065-0.858 0.182c-0.271 0.117-0.516 0.287-0.72 0.5l-12.172 12.171-12.185-12.185c-0.207-0.213-0.454-0.382-0.727-0.497s-0.567-0.175-0.864-0.175z"
        fill={pathColor || '#000000'}
      />
    </svg>
  );
};

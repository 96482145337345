interface IProps {
  pathColor?: string;
  className?: string;
}

export const IconHeartStroke = ({ pathColor, className }: IProps) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
    >
      <title>heart-line</title>
      <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.9091"
        stroke={pathColor || '#000000'}
        d="M16 5.697l-0.289-0.295c-3.261-3.323-8.56-3.324-11.821 0-3.246 3.308-3.246 8.66 0 11.969l11.072 11.284c0.274 0.279 0.648 0.436 1.038 0.436s0.765-0.157 1.038-0.436l11.072-11.284c3.247-3.308 3.247-8.661 0-11.969-3.261-3.324-8.56-3.324-11.821 0-0 0-0 0-0 0l-0.289 0.295z"
      />
    </svg>
  );
};

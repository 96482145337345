'use client';

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getNotifications } from '@/actions/getNotifications';
import type { NotificationsQuery } from '@/graphql/gql-generated';
import { useAsyncError } from '@/hooks/useAsyncError';

type Notification = NotificationsQuery['notifications'][0];

interface NotificationContextType {
  notifications: Notification[];
  addNotification: (_notification: Notification) => void;
  removeNotification: (_id: string) => void;
  isLoading: boolean;
  error: Error | null;
  refetchNotifications: () => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType | null>(null);

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationProvider',
    );
  }
  return context;
};

interface NotificationProviderProps {
  children: React.ReactNode;
  initialNotifications: Notification[];
  pollingInterval?: number;
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
  initialNotifications,
  pollingInterval = 60000, // デフォルトは1分
}) => {
  const [notifications, setNotifications] =
    useState<Notification[]>(initialNotifications);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const addNotification = (newNotification: Notification) => {
    setNotifications((prev) => [newNotification, ...prev]);
  };

  const removeNotification = (id: string) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id),
    );
  };

  const throwError = useAsyncError();
  const fetchNotifications = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await getNotifications();
      if (!data) {
        throwError({
          name: 'InternalServerError',
          message: '予期せぬエラーが発生しました',
        });
      }
      setNotifications(data!);
      setError(null);
    } catch (e) {
      setError(e instanceof Error ? e : new Error('通知の取得に失敗しました'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(fetchNotifications, pollingInterval);
    return () => clearInterval(intervalId);
  }, [fetchNotifications, pollingInterval]);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      notifications,
      addNotification,
      removeNotification,
      isLoading,
      error,
      refetchNotifications: fetchNotifications,
    }),
    [notifications, isLoading, error, fetchNotifications],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

interface IProps {
  pathColor?: string;
  className?: string;
}

export const IconHeartFill = ({ pathColor, className }: IProps) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
    >
      <title>heart-fill</title>
      <path
        fill={pathColor || '#000000'}
        d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.347-11.4-15.373 0-4.12 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.773 1.453-1.693 3.68-2.773 6-2.773 4.107 0 7.333 3.213 7.333 7.333 0 5.027-4.533 9.147-11.4 15.373l-1.933 1.76z"
      />
    </svg>
  );
};

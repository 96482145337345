export enum NotificationType {
  AgeVerificationComplete = 'App\\Notifications\\AgeVerificationCompleteNotification',
  AgeVerificationRejected = 'App\\Notifications\\AgeVerificationRejectedNotification',
  CustomVerifyEmail = 'App\\Notifications\\CustomVerifyEmail',
  Like = 'App\\Notifications\\LikeNotification',
  LikesBonusGranted = 'App\\Notifications\\LikesBonusGrantedNotification',
  Match = 'App\\Notifications\\MatchNotification',
  NewMessage = 'App\\Notifications\\NewMessageNotification',
  NewRecommendation = 'App\\Notifications\\NewRecommendationNotification',
  ProfileImageApproved = 'App\\Notifications\\ProfileImageApprovedNotification',
  ProfileImageRejected = 'App\\Notifications\\ProfileImageRejectedNotification',
  ResetPassword = 'App\\Notifications\\ResetPasswordNotification',
  UserProfileGenericTextboxDataApproved = 'App\\Notifications\\UserProfileGenericTextboxDataApprovedNotification',
  UserProfileGenericTextboxDataRejected = 'App\\Notifications\\UserProfileGenericTextboxDataRejectedNotification',
}

interface IProps {
  pathColor?: string;
  className?: string;
}

export const IconArrow = ({ pathColor, className }: IProps) => {
  return (
    <svg
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.02622 8.77742L9.67021 2.13865C10.1616 1.64728 10.1616 0.852718 9.67021 0.366572C9.17884 -0.124801 8.38428 -0.119574 7.8929 0.366572L0.365488 7.88876C-0.110204 8.36445 -0.120658 9.12765 0.328895 9.61902L7.88768 17.1935C8.13336 17.4392 8.45746 17.5594 8.77633 17.5594C9.0952 17.5594 9.4193 17.4392 9.66498 17.1935C10.1564 16.7021 10.1564 15.9076 9.66498 15.4214L3.02622 8.77742Z"
        fill={pathColor || '#000000'}
      />
    </svg>
  );
};
